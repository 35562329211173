import React from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonText,
  IonCol,
  IonRow,
  IonGrid,
} from '@ionic/react';
import Layout from '../../components/layout';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { showToast } from '../../store/actions';
const { SelectOption } = Mobiscroll;

const { getRestaurant, setPortionToPay, getTableAmountToPay, setTablePayMethod } = Basket;

class SplitAmount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalBillMembers: null,
      portion: null,
      splitPayByMembers: true,
      amountToPay: null,
      amountHigher: false,
      payEventsVisible: false,
    };
  }

  componentDidMount() {
    if (Basket.bill_data.pay_method !== '2') {
      forwardTo('/table-bill-pay');
    }
  }
  setAmountToPay = (value) => {
    this.setState({ amountToPay: value });
    if (value > Basket.amount_left_to_pay / 100) {
      this.setState({ amountHigher: true, amountToPay: value });
    } else {
      Basket.setTablePaymentAmount(value * 100);
      this.setState({ amountHigher: false, amountToPay: value });
    }
  };

  handleSubmit = () => {
    Basket.setBillPayServiceCharge(0);
    forwardTo('/bill-summary');
  };

  render() {
    const { totalBillMembers, portion, splitPayByMembers, amountToPay } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    const { __, total } = this.props;
    return (
      <Loading transparent>
        <Layout
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          noPadding
          hideSecondToolbar={true}
        >
          <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <div className="flex-min">
                <Title>{__('Split the bill')}</Title>
              </div>
              <IonGrid>
                <IonRow>
                  <IonCol className="item-name">{__('Bill total')}</IonCol>
                  <IonCol className="righted">{Basket.getTotalBill()}</IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    {__('Already paid')}{' '}
                    {Basket.getPayEvents()?.length > 0 && (
                      <SmallText
                        className="pointer underlined"
                        onClick={() =>
                          this.setState({ payEventsVisible: !this.state.payEventsVisible })
                        }
                      >
                        {__('view details')}
                      </SmallText>
                    )}
                  </IonCol>
                  <IonCol className="righted">{Basket.getPaidAmount()}</IonCol>
                </IonRow>
                {this.state.payEventsVisible && (
                  <>
                    {Basket.getPayEvents().map((el, i) => (
                      <IonRow ket={i}>
                        <IonCol>
                          <StrongText className="bold">{el.user_id}</StrongText>
                        </IonCol>
                        <IonCol class="righted">
                          <StrongText className="bold">
                            {Basket.formatPrice(el.payment_amount / 100)}
                          </StrongText>
                        </IonCol>
                      </IonRow>
                    ))}
                  </>
                )}
                <IonRow>
                  <IonCol className="item-name sc-ion-label-ios-h">
                    {__('TOTAL LEFT TO PAY')}
                  </IonCol>
                  <IonCol className="righted">{Basket.getAmountLeftToPay()}</IonCol>
                </IonRow>
              </IonGrid>
              <Spacer />
              <div className="centered">
                <NormalText className="block ">{__(`I will pay`)}</NormalText>
              </div>
              <div className="box-wrapper  inline-box-wrapper">
                <div>
                  <NormalText>{Basket.getCurrency().label}</NormalText>
                </div>
                <IonItem lines="none" className="custom-amount-field">
                  <IonInput
                    onIonChange={(e) => this.setAmountToPay(e.target.value)}
                    max={Basket.amount_left_to_pay / 100}
                    min="0"
                    required={true}
                    type="number"
                    pattern="decimal"
                    inputmode="decimal"
                    value={
                      this.state.amountToPay || this.state.amountToPay == ''
                        ? this.state.amountToPay
                        : Basket.getTablePayAmountUnformatted()
                    }
                  />
                </IonItem>
              </div>

              <div className="flex-min split-pay-wrapper">
                <IonButton
                  disabled={this.state.amountHigher}
                  onClick={this.handleSubmit}
                  expand="block"
                  className="round-button ion-margin-top"
                >
                  {__('Pay')} {Basket.getTablePaymentAmount()}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { checkedLocationCodeData } = state.orders;
  const { restaurants, selectedRestaurant, tableData } = state.restaurants;

  return {
    auth,
    profile,
    checkedLocationCodeData,
    restaurants,
    cards: state.orders.cards || [],
    selectedRestaurant: selectedRestaurant || {},
    tableData: tableData,
  };
};

export default connect(stateToProps)(withTranslation(SplitAmount));
