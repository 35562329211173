import React from 'react';
import { IonButton, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react';
import Loading from '../../components/spinner';
import { pencilOutline } from 'ionicons/icons';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Sectiontitle, Spacer } from '../../components/common';
import Basket from '../../lib/basket';
//import { makeKey } from '../../lib/utils'
import ValidateButton from '../../components/validateButton';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { connect } from 'react-redux';
import OrderContent from '../../components/orderContent';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isDefined,
  isWebConfig,
} from '../../lib/utils';
import { setModal } from '../../store/actions';
import { PillGroup } from '../../components/pill';
import ContentHeader from '../../components/contentHeader';
import './index.css';

const {
  getPickUpPoint,
  getDeliveryAddress,
  changeItemQuantity,
  itemsCount,
  setMobile,
  getMobile,
  getTotal,
  getAllergen,
  getItems,
  isMinimumOrderTotalSatisfied,
  getDeliveryOption,
} = Basket;

const isWeb = () => Capacitor.getPlatform() === 'web';

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      mobile: getMobile() || '',
      allergenModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };

  handleSubmit = () => {
    const { cards } = this.props;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    if (getTotal() === 0) {
      Basket.createOrder();
      return;
    }
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout');
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout');
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  handleNativeSubmit = (allergens = []) => {
		const { cards } = this.props;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    if (getTotal() === 0) {
      Basket.createOrder();
      return;
    }
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        Basket.createOrder('NativePayment');
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        Basket.createOrder('NativePayment');
      } else {
        forwardTo('/contact-details');
      }
    }
	};

  render() {
    const { __, profile, dispatch, auth } = this.props;
    const { quantityModal, mobile, allergenModalOpen } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const appliedVouchers = Basket.getAppliedVocuher();
    const isAuth = auth.loggedIn;
    const labelTitle =
      Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType();
    const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' };
    return (
      <Loading>
        <Layout color="white" headerTitle={__('Order Summary')} backHandler={this.backHandler}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y checkout">
              {Basket.getItems().length > 0 ? (
                <ContentHeader
                  __={__}
                  deliveryOption={getDeliveryOption()}
                  orderType={labelTitle}
                  deliveryAddress={getDeliveryAddress()}
                  pickUpPoint={getPickUpPoint()}
                />
              ) : null}
              <IonItem className="contact-number" lines="full">
                <div tabIndex="-1"></div>
                <IonLabel {...contactLabelArrg} className="ion-text-wrap contact-number-label">
                  <Sectiontitle>{__('Contact number')}</Sectiontitle>
                </IonLabel>
                <IonInput
                  className="mobile-field"
                  onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                  clearInput
                  required={true}
                  type="tel"
                  pattern="tel"
                  inputmode="tel"
                  value={mobile}
                >
                  {mobile === '' ? (
                    // <IonButtons slot='end' className='no-margin'>
                    // 	<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
                    // 	</IonButton>
                    // </IonButtons>
                    <IonIcon icon={pencilOutline} className="contact-number-icon" />
                  ) : null}
                </IonInput>
              </IonItem>
              <div className="righted">
                <IonButton
                  fill="clear"
                  color="white"
                  className="link link-add-items"
                  onClick={() => forwardTo('/order')}
                >
                  <strong>+ {__('Add Items')}</strong>
                </IonButton>
              </div>
              {itemsCount() > 0 ? (
                <OrderContent
                  handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                  textColor="primary"
                />
              ) : (
                <IonItem>
                  <div
                    tabIndex="-1"
                    className="sectiontitle"
                    style={{ width: '100vh', textAlign: 'center' }}
                  >
                    {__('No items')}
                  </div>
                </IonItem>
              )}
              <div className="validate-content">
                <ValidateButton />
                {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                  <div className="verified-content">
                    <SmallText color="grey">
                      {__('You can earn, but not redeem points until your account is verified')}
                    </SmallText>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex-min">
              {allergens.length > 0 ? (
                <IonButton
                  fill="clear"
                  expand="block"
                  className="link underlined"
                  color="dark"
                  onClick={() => this.setState({ allergenModalOpen: true })}
                >
                  {__('View allergen information')}
                </IonButton>
              ) : null}
              <IonButton
                disabled={itemsCount() === 0 /*|| appliedPoints > 0*/}
                className={`round-button redeem-points-btn ${itemsCount() === 0 || !valid ? 'disabled' : '' }`}
                onClick={() => {
                  if ((itemsCount() === 0 || !valid) && isAuth) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    forwardTo('/apply-vouchers');
                  }
                }}
                expand="block"
                fill="outline"
                color="dark"
              >
                {appliedVouchers && appliedVouchers.length > 0
                  ? __('Replace Voucher').toUpperCase()
                  : __('Apply Vouchers').toUpperCase()}
              </IonButton>
              <IonButton
                disabled={itemsCount() === 0 || !valid}
                onClick={() => forwardTo('/apply-points')}
                className="round-button "
                expand="block"
                fill="outline"
                color="primary"
              >
                {/* <IonIcon slot="start" icon={ starOutline } /> */}
                {__('Redeem Points').toUpperCase()}
              </IonButton>
              {isWeb() ? (
                 <IonButton
                  disabled={itemsCount() === 0}
                  onClick={this.handleSubmit}
                  className="round-button "
                  expand="block"
                  color="secondary"
                >
                  {getTotal() === 0 ? __('Pay').toUpperCase() : __('Checkout').toUpperCase()}
                </IonButton>
              ) : null}
              {!isWeb() && getConfig()?.prebuildPayment?.showOnNative ? (
                <IonButton
                  style={{ marginBottom: '10px' }}
                  color="secondary"
                  className="uppercase round-button"
                  onClick={() => {
                    Basket.createStripeOrder(getConfig().prebuildPayment?.label);
                  }}
                  expand="block"
                >
                  {__(getConfig().prebuildPayment?.label)}
                </IonButton>
              ) : null}
              {!isWeb() && getConfig().nativePayment?.isVisible ? (
                <IonButton
                  disabled={itemsCount() === 0}
                  style={{ marginBottom: '10px' }}
                  className="uppercase round-button"
                  color="secondary"
                  onClick={() => {
                    this.handleNativeSubmit();
                  }}
                  expand="block"
                >
                  {__(getConfig().nativePayment?.label)}
                </IonButton>
              ) : null}
            </div>
          </div>

          <Modal
            title={__('Change quantity')}
            action={this.updateItemQuantity}
            actionLabel={__('Change')}
            isOpen={!!quantityModal}
            onDidDismiss={() => this.setState({ quantityModal: null })}
          >
            {quantityModal && quantityModal.item ? (
              <Incrementer
                allowNegative={false}
                quantity={quantityModal.item.quantity}
                onUpdate={this.onIncrementerUpdate}
              />
            ) : null}
          </Modal>
          <Modal
            cssClass="allergen-modal"
            isOpen={allergenModalOpen}
            onDidDismiss={() => this.setState({ allergenModalOpen: false })}
          >
            <div className="absolute-content flex-row-wrapper">
              <div className="scrollable-y rhino">
                <Title className="centered">{__('Allergen Information')}</Title>
                <Spacer />
                {getItems().map((item, index) => {
                  let allAllergens = [];
                  let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                  if (data && data.length > 0) {
                    allAllergens.push(
                      <div key={'allergen-' + index}>
                        <Sectiontitle>
                          {item.quantity}x {item.item.productName}
                        </Sectiontitle>
                        <PillGroup items={data[0].allergens} borderColor="primary" />
                        <Spacer />
                      </div>,
                    );
                  }
                  // check allergens for sub-items
                  if (isDefined(item.selectedChoices)) {
                    item.selectedChoices.forEach((group) => {
                      group.forEach((item) => {
                        if (
                          isDefined(item.itemRichData) &&
                          isDefined(item.itemRichData.allergenCodes) &&
                          item.itemRichData.allergenCodes.length > 0
                        ) {
                          let data1 = allergens.find((allergen) => allergen[1].sku === item.sku);
                          if (data1 && data1.length > 0) {
                            allAllergens.push(
                              <div key={'allergen_subitem_' + index + '_' + item.productName}>
                                <Sectiontitle>{item.productName}</Sectiontitle>
                                <PillGroup items={data1[0].allergens} borderColor="primary" />
                                <Spacer />
                              </div>,
                            );
                          }
                        }
                      });
                    });
                  }
                  return allAllergens;
                })}
              </div>
              <div className="flex-min centered">
                <Spacer />
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => this.setState({ allergenModalOpen: false })}
                >
                  {__('Hide allergen information')}
                </IonButton>
              </div>
            </div>
          </Modal>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth } = store.profile;
  return {
    basketUpdated: store.orders.basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    itemAllergens: store.restaurants.itemAllergens,
    auth,
  };
};

export default connect(mapStateToProps)(withTranslation(OrderSummary));
