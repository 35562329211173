import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IonItem,
  IonLabel,
  IonButton,
  IonList,
  IonInput,
  IonGrid,
  IonCard,
  IonCol,
  IonRow,
} from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, Spacer, Subtitle, Title } from '../../components/common';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Modal from '../../components/modal';

import { forwardTo, isEmptyObject, isEmptyArray, validateProfileData } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';

const LabelStyle = {
  fontSize: 14,
  fontWeight: 700,
  color: '#284734',
};

class PayTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableItems: [],
      totalPrice: 0,
      paidAmount: 0,
      isVoucherClicked: false,
      loading: true,
      points: 0,
      payMethod: null,
      tableMembers: 0,
    };
  }
  componentDidMount() {
    if (Basket.bill_data.pay_method) {
      forwardTo('/table-bill-pay');
    }
  }
  onPayTableClick = () => {
    forwardTo('/bill-summary');
  };
  setTablePayMethod = () => {
    this.props.dispatch({
      type: SET_TABLE_PAY_METHOD,
      data: {
        pay_method: this.state.payMethod,
        table_bill_id: Basket.bill_data.id,
        total_members_to_pay: parseInt(this.state.tableMembers),
      },
    });
  };
  render() {
    const { __, auth, profile } = this.props;
    const { tableItems, points, paidAmount, totalPrice, isVoucherClicked } = this.state;
    const deliveryOption = Basket.getDeliveryOption();
    const isAuth = auth.loggedIn;
    const valid = validateProfileData(profile).isValid;
    const tableData = isEmptyObject(this.props.tableData)
      ? Basket.getBillPayData()
      : this.props.tableData;
    return (
      <Loading transparent>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} noPadding>
          <div className="scrollable-y absolute-content">
            <div className="pay-table">
              <Title>{__('Pay Your Bill')}</Title>
              <NormalText>
                {' '}
                {__(
                  `Pay the bill for Table ${Basket.getTableNumber()} at ${Basket.getRestauranName()}`,
                )}
              </NormalText>

              <IonGrid class="table-summary">
                {Basket.table_items.map((item, index) => {
                  return (
                    item.quantity > 0 && (
                      <>
                        <IonRow lines="none" key={index}>
                          <IonCol style={LabelStyle}>
                            {item.quantity} x {__(item.itemName)}
                          </IonCol>
                          <IonCol className="righted">
                            {Basket.formatPrice(item.unitAmount * item.quantity)}
                          </IonCol>
                        </IonRow>
                        <>
                          {!isEmptyArray(item.subLineItems)
                            ? item.subLineItems.map((subitem) => {
                                return (
                                  <IonRow className="subitem" key={subitem.id}>
                                    <IonCol>
                                      {subitem.quantity} x {subitem.itemName}
                                    </IonCol>
                                    <IonCol className="righted">
                                      {Basket.formatPrice(subitem.unitAmount * subitem.quantity)}
                                    </IonCol>
                                  </IonRow>
                                );
                              })
                            : null}
                        </>
                      </>
                    )
                  );
                })}
                {/* <div className="line" style={{ backgroundColor: '#284734' }} /> */}
                <IonRow>
                  <IonCol style={LabelStyle}>{__('Total Bill')}</IonCol>
                  <IonCol className="righted">{Basket.getTotalBill()}</IonCol>
                </IonRow>

                {Basket.paid_amount > 0 && (
                  <IonRow>
                    <IonCol style={LabelStyle}>{__('Already Paid Amount')}</IonCol>
                    <IonCol className="righted">{Basket.getPaidAmount()}</IonCol>
                  </IonRow>
                )}

                <IonRow>
                  <IonCol style={LabelStyle}>{__('Total Outstanding Amount')}</IonCol>
                  <IonCol className="righted">{Basket.getAmountLeftToPay()}</IonCol>
                </IonRow>
              </IonGrid>

              <Spacer />

              <IonButton
                expand="block"
                onClick={() => this.setState({ isSetPayMethodModalOpen: true, payMethod: 0 })}
                className="round-button "
                color="secondary"
                disabled={tableData && tableData.payMethod === 'Pay by split' ? true : false}
              >
                {__('PAY FOR ENTIRE TABLE')}
              </IonButton>
              <IonButton
                expand="block"
                onClick={() => this.setState({ isSetPayMethodModalOpen: true, payMethod: 1 })}
                className="round-button "
                color="secondary"
              >
                {__('SPLIT THE BILL')}
              </IonButton>
              <IonButton
                expand="block"
                onClick={() => this.setState({ isSetPayMethodModalOpen: true, payMethod: 3 })}
                className="round-button "
                color="secondary"
              >
                {__('PAY BY ITEM')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <Modal
          className="set-pay-method-modal"
          isOpen={this.state.isSetPayMethodModalOpen}
          onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
        >
          <Subtitle>{__('Are you sure you want to set this?')}</Subtitle>
          {this.state.payMethod == 1 && !Basket.getLeadUser() && (
            <>
              <Spacer />
              <NormalText>{__('Number of table members')}</NormalText>
              <IonItem lines="none" className="box-wrapper inline-box-wrapper">
                <IonInput
                  className="split-number-input"
                  onIonChange={(e) => this.setState({ tableMembers: e.target.value })}
                  clearInput
                  required={true}
                  type="number"
                  pattern="number"
                  inputmode="number"
                  value={this.state.tableMembers}
                />
                <div>
                  <NormalText>{__('People')}</NormalText>
                </div>
              </IonItem>
              <Spacer />
            </>
          )}
          <IonButton
            disabled={!this.state.tableMembers > 0 && this.state.payMethod == 1}
            expand="block"
            onClick={() => this.setTablePayMethod()}
          >
            {__('Confirm')}
          </IonButton>
        </Modal>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const { tableData } = store.restaurants;
  const { auth, profile } = store.profile;

  return {
    tableData: tableData,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    auth,
    profile,
  };
};

export default connect(mapStateToProps)(withTranslation(PayTable));
